import React from 'react';
import FarmersMandi from './components/FarmersMandi';

function App() {
  return (
      <div className="App">
        <FarmersMandi />
      </div>
  );
}

export default App;
