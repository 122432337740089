import React from 'react';
import styles from './FarmersMandi.module.css';

const FarmersMandi = () => {
    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <div className={styles.logoContainer}>
                    <div className={styles.logo}>farmersMandi</div>
                    <div className={styles.subtitle}>by Brih Solutions Private Limited</div>
                </div>
                <nav className={styles.nav}>
                    <a href="#about">About</a>
                    <a href="#features">Features</a>
                    <a href="#impact">Impact</a>
                    <a href="#contact">Contact</a>
                </nav>
            </header>

            <main className={styles.bentoGrid}>
                <section className={`${styles.bentoItem} ${styles.hero}`}>
                    <h1>Revolutionizing Vegetable Distribution</h1>
                    <p>Connecting wholesalers to retailers with cutting-edge technology</p>
                    {/*<div className={styles.heroImagePlaceholder}>

                        <img src="/images/fmandiimage.jpeg" alt="farmersMandi Hero" className={styles.heroImg}/>

                    </div>*/}
                </section>

                <section id="about" className={`${styles.bentoItem} ${styles.about}`}>
                    <h2>About farmersMandi</h2>
                    <p>farmersMandi is a pioneering sales and distribution platform designed specifically for vegetable wholesalers. We leverage advanced technology to streamline the entire supply chain process, from sourcing to delivery, ensuring efficiency and reducing waste at every step.</p>
                </section>

                <section id="features" className={`${styles.bentoItem} ${styles.features}`}>
                    <h2>Empowering the Supply Chain</h2>
                    <div className={styles.featureGrid}>
                        <div className={styles.featureCard}>
                            <h3>Vast Network</h3>
                            <p>Gain access to over 8,000 retailers, vegetable shops, hotels, and supermarkets. Our extensive network provides unparalleled market reach, allowing you to expand your business rapidly and efficiently.</p>
                        </div>
                        <div className={styles.featureCard}>
                            <h3>Dedicated Sales Force</h3>
                            <p>Benefit from our strong network of sales executives who work tirelessly to maximize your sales. Our team's expertise in local markets ensures your products reach the right customers at the right time.</p>
                        </div>
                        <div className={styles.featureCard}>
                            <h3>Tech-Driven Operations</h3>
                            <p>Utilize our state-of-the-art tech tools and operational support to simplify, organize, and scale your business. From warehouse management to optimal routing and delivery, our technology optimizes every aspect of your operations.</p>
                        </div>
                    </div>
                </section>

                <section id="impact" className={`${styles.bentoItem} ${styles.impact}`}>
                    <h2>Our Impact</h2>
                    <div className={styles.stats}>
                        <div>
                            <span className={styles.number}>10,000+</span>
                            <span className={styles.label}>Tonnes of Produce Sold</span>
                        </div>
                        <div>
                            <span className={styles.number}>8,000+</span>
                            <span className={styles.label}>Active Customers</span>
                        </div>
                        <div>
                            <span className={styles.number}>180,000+</span>
                            <span className={styles.label}>Successful Deliveries</span>
                        </div>
                    </div>
                    <p>In just three years, we've revolutionized the vegetable supply chain, facilitating the movement of over 10,000 tonnes of fresh produce to more than 8,000 customers through 180,000+ deliveries. Our platform has not only increased efficiency but also reduced waste, benefiting both farmers and consumers.</p>
                </section>

                <section id="contact" className={`${styles.bentoItem} ${styles.cta}`}>
                    <h2>Join the Revolution</h2>
                    <p>Whether you're a retailer looking for reliable suppliers or a wholesaler seeking to expand your market reach, farmersMandi is here to propel your business forward. Our platform offers the tools, network, and support you need to thrive in the competitive world of vegetable distribution.</p>
                    <div className={styles.contactInfo}>
                        <a href="mailto:contact@fmandi.in" className={styles.button}>contact@fmandi.in</a>
                        <a href="tel:9036728830" className={styles.button}>903-672-8830</a>
                    </div>
                </section>
            </main>

            <footer className={styles.footer}>
                <p>&copy; 2024 farmersMandi by Brih Solutions Private Limited</p>
                <a href="https://www.farmersmandi.in" target="_blank" rel="noopener noreferrer">www.farmersmandi.in</a>
            </footer>
        </div>
    );
};

export default FarmersMandi;
